import { HashParser } from "./HashParser";
import { ERROR_INVALID_PARAMETER_TYPE } from "./HashParameter";

export const ERROR_MISSING_PARAMETER = "Cannot find parameter by that name";
export const ERROR_PARAMETER_ALREADY_DEFINED = "Parameter already defined";

export class HashParameters {
   constructor(onHashChange, scope) {
      this.parameters = {};
      this.hashParser = new HashParser();

      window.addEventListener(
         "hashchange",
         () => {
            this.parse();

            if (scope) {
               onHashChange.call(scope, this.toObject());
            } else {
               onHashChange(this.toObject());
            }
         },
         false
      );
   }

   add(name, parameterDefinition) {
      if (!parameterDefinition.isValidType()) {
         throw new Error(ERROR_INVALID_PARAMETER_TYPE);
      }

      if (this.parameters.hasOwnProperty(name)) {
         throw new Error(ERROR_PARAMETER_ALREADY_DEFINED);
      }

      this.parameters[name] = parameterDefinition;
   }

   findParameterByURLName(urlName) {
      let key = "";

      for (key in this.parameters) {
         let definition = this.getDefinition(key);

         if (definition.urlName === urlName) {
            return definition;
         }
      }

      throw new Error(ERROR_MISSING_PARAMETER);
   }

   get(name) {
      let parameter = this.getDefinition(name);
      return parameter.getValue();
   }

   getDefinition(name) {
      this.validateParameterName(name);
      return this.parameters[name];
   }

   parse() {
      return new Promise(resolve => {
         let parsedHash = this.hashParser.parse();
         let key;

         for (key in parsedHash) {
            let definition = this.findParameterByURLName(key);
            definition.setValue(parsedHash[key]);
         }

         return resolve(this.toObject());
      });
   }

   set(name, value) {
      if (typeof name === "object") {
         for (let key in name) {
            try {
               let param = this.getDefinition(key);
               param.setValue(name[key]);
            } catch (e) {
               // No problem here. Just no matching parameter
            }
         }
      } else {
         this.validateParameterName(name);
         this.parameters[name].setValue(value);
      }
   }

   toObject(useURLKeyNames) {
      let result = {};
      let key;

      for (key in this.parameters) {
         if (useURLKeyNames) {
            result[this.parameters[key].urlName] = this.parameters[
               key
            ].getValue();
         } else {
            result[key] = this.parameters[key].getValue();
         }
      }

      return result;
   }

   toString() {
      let result = "";
      let totalKeys = Object.keys(this.parameters).length;
      let currentKeyIndex = 0;
      let key;

      for (key in this.parameters) {
         currentKeyIndex++;

         result += this.parameters[key].toString();
         if (currentKeyIndex < totalKeys) {
            result += "&";
         }
      }

      return result;
   }

   updateURL() {
      window.location.hash = `#!${this.toString()}`;
   }

   validateParameterName(name) {
      if (!this.parameters.hasOwnProperty(name)) {
         throw new Error(ERROR_MISSING_PARAMETER);
      }
   }
}

window.HashParameters = HashParameters;
