export class HashParser {
	constructor() {
	}

	getHashFromURL() {
		return window.location.hash;
	}

	parse() {
		let result = {};
		let rawHash = this.getHashFromURL();
		let hashArray = [];

		if (rawHash.indexOf("#!") > -1) {
			rawHash = this.stripHash(rawHash);
			hashArray = this.splitHashToArray(rawHash);

			hashArray.forEach((hashPair) => {
				let kvp = this.splitKeyValuePair(hashPair);
				let key = kvp[0];
				let value = kvp[1];

				if (value !== undefined) {
					result[key] = decodeURIComponent(value);
				} else {
					result[key] = undefined;
				}
			});
		}

		return result;
	}

	splitHashToArray(hash) {
		return hash.split("&");
	}

	splitKeyValuePair(kvp) {
		return kvp.split("=");
	}

	stripHash(hash) {
		return hash.substr(2);
	}
}