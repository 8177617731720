export const ERROR_INVALID_PARAMETER_TYPE = "Invalid parameter type";

export class HashParameter {
	constructor(urlName, type, defaultValue) {
		this.urlName = urlName;
		this.type = type;
		this.defaultValue = defaultValue;
		this.value = this.defaultValue;

		this.validTypes = [
			"number",
			"string"
		];
	}

	isValidType() {
		let result = false;

		this.validTypes.forEach((type) => {
			if (type === this.type) {
				result = true;
			}
		});

		return result;
	}

	getNumberValue(value) {
		return window.parseInt(value, 10);
	}

	getStringValue(value) {
		return value;
	}

	getValue() {
		if (this.type === "number") {
			return this.getNumberValue(this.value);
		}

		if (this.type === "string") {
			return this.getStringValue(this.value);
		}

		return this.value;
	}

	setValue(value) {
		this.value = value;
	}

	toString() {
		return `${encodeURIComponent(this.urlName)}=${encodeURIComponent(this.value)}`;
	}
}

window.HashParameter = HashParameter;